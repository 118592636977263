import react from "react";
import { Box, Flex } from "@chakra-ui/react";
import { Content } from "@prismicio/client";
import { PrismicRichText, SliceComponentProps } from "@prismicio/react";
import styles from "./FullWidthSplitSection.module.css";
import FadeInWhenVisible from "@/components/FadeInWhenVisible/FadeInWhenVisible";

/**
 * Props for `FullwidthSplitSection`.
 */
export type FullwidthSplitSectionProps =
  SliceComponentProps<Content.FullwidthSplitSectionSlice>;

/**
 * Component for "FullwidthSplitSection" Slices.
 */
const FullwidthSplitSection = ({
  slice,
}: FullwidthSplitSectionProps): JSX.Element => {
  const flipped = slice.primary.flipped;
  const imageUrl = slice.primary.backgroundimage.url;

  return (
    <FadeInWhenVisible>
      <Flex
        className={[
          styles.fullWidthSplitSection,
          flipped ? styles.flipped : "",
        ].join(" ")}
      >
        <Box
          className={styles.fullWidthSplitSection__image}
          backgroundImage={`${imageUrl ? imageUrl : "/eca-503.png"}`}
        ></Box>
        <Box
          className={["container", styles.fullWidthSplitSection__content].join(
            " "
          )}
        >
          <PrismicRichText field={slice.primary.content} />
        </Box>
      </Flex>
    </FadeInWhenVisible>
  );
};

export default FullwidthSplitSection;
